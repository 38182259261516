html *{
  font-family: 'Montserrat', sans-serif;
}

h1,h2,h3,h4,h5,h6,span,p,button,a,ul,li{
  font-family: 'Montserrat', sans-serif;
}
a{
  color: #013360;
}
.btn{
  border-radius: 30px;
  border:none;
  font-size: 12px;
  padding: 15px 29px 15px;

}

.card-body{
  text-align: center;
}


.pricingcontent{
  box-shadow: 1px 5px 15px #eee;
  padding: 2em;
}
.border-right{
  border-right: 2px solid #6d6d6d;
}


.btn:focus, .btn:hover{
  box-shadow: 0 14px 26px -12px rgba(50, 66, 51, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(57, 85, 58, 0.2);


}
.btn-success{
  background-color: #24D89B;
}
.btn-toolbar .btn{
  margin-left: 1em;
  padding: 15px 29px 15px;
  font-size: 12px;

}
.subscribeus  .btn-success{
  padding: 15px 29px 15px;
  font-size: 18px;
  border-radius: 6px;
}
.textBload{
  font-weight: bold;
}
.textRegular{
  font-weight: normal;
}
.textMedium{
  font-weight: 500;
}
.textSize14{
  font-size: 14px;;
}

.headerHomePage{
  /* background: linear-gradient(90deg, rgba(255,255,255,1) 68%, rgba(57,181,74,1) 63%); */
  padding-bottom: 8em;
}

.headerHomePage{
    background-image : url('./img/background.webp');
    background-size: contain;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position-x: right;

}


.textGreen{
  color: #39b54a;
}
.textBlue{
  color: #013360;
}
.btn-blue{
  background-color:#013360;
}
.custom-table2 th{
  white-space: nowrap;
  font-weight: 500;
  border-bottom: none;
}

.custom-table2 .table td, .table th{
  border-bottom: none;
  border-top: none;
  border-right: 5px solid #fff;
  text-align: center;
  padding: 2px;
}

.custom-table2 td{
  background-color: #fafafa;
}
.custom-table2 .border-table{
  border: 1px solid #eee;
  margin: 8px;
  padding: 0px 7px 0px 7px;
}


#basic-navbar-nav .nav-link{
  color: #013360;
  font-weight: 500;
  padding-right: 1em;
padding-left: 1em;
}
.bg-gray{
  background-color: #fafafa;
}
.custom-card-home .card-title{
  text-align: center;
  color: #013360;
  font-weight: bold;
  margin-bottom: 1.5rem;
}


.custom-card-home  .card-text{
text-align: center;
font-size: 14px;
}
.custom-card-home img{
  width: 100px;
    padding: 2em 0em 1em 0em;
}
.custom-card-home .card{
  padding: 2em;
  border: 1px solid#f1f1f1;
  margin: 5px;
 
  transition: transform .2s;
  
}
.custom-card-home .card:hover{
  box-shadow: 10px 10px 15px #f1f1f1;
  transform: scale(1.001);
  z-index: 1000;
}


.custom-card-home2 .card{
  border : none;
  background-color: transparent;
} 


.custom-card-home2 .img-container{
  background-color: #fff;
  border-radius: 2em;


}
.custom-card-home2 img.card-img{
  width: 200px;

}
.custom-card-home2 h4{
  position: absolute;
  left: 73px;
  top: 0;
  font-weight: bold;

}
.custom-card-home2 .card-title{
  text-align: center;
  color: #39b54a;
  font-weight: bold;
  margin-bottom: 1.5rem;
}


.custom-card-home2  .card-text{
text-align: center;
font-size: 14px;
padding: 0em 3em 0em 3em;
}
.custom-card-home2 .arrow{
  position: absolute;
  right: -21px;
  top: 27%;
}

.custom-tab .nav-pills .nav-link.active, .nav-pills .show > .nav-link{
  background-color:  #39b54a;
}


.custom-tab .nav-pills .nav-link{
  border : 1px solid #eee;
  margin-bottom: 1em;
  padding: 1em 1em;
  border-radius: 0.5rem;

}

.custom-tab .nav-pills .nav-link::after{
  display: inline-block;
    content: "";
    border-top: 0.5em solid;
    border-right: .5em solid transparent;
    border-bottom: 0px;
    border-left: .5em solid transparent;
    position: absolute;
    right: 18px;
    margin-top: 9px;
    transform: rotate(-90deg);
  
}

.custom-tab .card{
  margin-top: 1em;
  
}

.custom-tab .card-header{
 background-color: #fff;
 order: 1;
 font-weight: bold;
 margin-bottom: 0px;
}


.custom-tab  .accordion>.card>.card-header{
  margin-bottom: 0px;
}
.custom-tab .collapse,.collapsing {
  order: 2;
}

.custom-tab .collapse.show + .card-header {
  background-color: #fff;
  border-top: 6px  solid #39b54a;
  
border-bottom: none;
margin-bottom: 0px;

}
.custom-tab .collapsing:not([style='']) + .card-header {
  background-color: #fff;
  border-top: 6px  solid #39b54a;
  border-bottom: none;
 
}

.footer{
  background-color: #013360;
  padding: 3em 3em;
}
.footer h4{
  color: #39b54a;
  font-weight: bold;
  margin-bottom: 1em;
}
.footer  ul li{
  padding-left: 3px;
  padding-bottom: 15px;
}
.footer  ul li::before{ 
  display: inline-block;
    content: "";
    border-top: 0.5em solid;
    border-right: .5em solid transparent;
    border-bottom: 0px;
    border-left: .5em solid transparent;
    position: absolute;
    margin-top: 9px;
    transform: rotate(-90deg);
    color: #39b54a;
    left: 10px;


}
.footer  ul{
  padding-left: 1em;
}
.footer  ul li a {
  color: #c3c3c3;

  
}



.footer .subscribeInput{
  background-color: #fff;
  border-radius: 1em;
  border: 3px solid #c3c3c3;
  padding:1em;
  width: 100%;

}



.footer-text{
  color: #c3c3c3;
}
.footer-text span{
  color: #39b54a;
  font-weight: bold;
}


 .bottomfooter{
  
  border-top : 1px solid #6d6d6d;
  background-color: #013360;

}
 .bottomfooter p{
  color: #c3c3c3;
  margin-bottom: 0;
  font-weight: 500;
}
.bottomfooter p span{
  font-weight: bold;
  color: #39b54a;
}

.homepageOklist li a{
  text-decoration: none;
  color: #000;
  padding-left: 23px;
}


.homepageOklist li::marker{
  padding: 0;
  margin:0;
}
.homepageOklist li{
  margin-bottom: 1em;
}

.homepageOklist{
  padding: 25px;
  list-style-image: url('./img/okicon.webp');

}


.custom-table .table-bordered td{
  border: 2px solid  #fff;
  font-weight: 500;
}
.custom-table .table-bordered tr{
 
  background-color:   #f7f7f7;
}

.custom-table .table-hover tbody tr:hover{
  background-color: #dddddd;
}

.custom-table .table thead th{
  font-weight: bold;
  color: #013360;
  background-color: #fff;
  border: none;

}

.custom-table .table thead th:not(:first-child){
  font-weight: 500;
  color: #013360;
  font-size: 14px;
}
.custom-table .table-bordered{
  border: none;
}

.arrow-up{
  display: inline-block;
  content: "";
  border-top: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-bottom: 0px;
  border-left: 0.4em solid transparent;
  position: absolute;
  margin-top: 10px;
  transform: rotate(0deg);
  color: red;
  margin-left: 5px;

}
.arrow-down{
  display: inline-block;
  content: "";
  border-top: 0.4em solid;
  border-right: 0.4em solid
  transparent;
  border-bottom: 0px;
  border-left: 0.4em solid
  transparent;
  position: absolute;
  transform: rotate(180deg);
  color:
  #39b54a;
  margin-left: 5px;
  margin-top: 10px;

}

.arrow-down-green{
  display: inline-block;
    content: "";
    border-top: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-bottom: 0px;
    border-left: 0.4em solid transparent;
    position: absolute;
    transform: rotate(0deg);
    color: #fff;
    margin-left: -13px;
    margin-top: 20px;
}


.arrow-right{
  display: inline-block;
  content: "";
  border-top: 0.4em solid;
  border-right: 0.4em solid
  transparent;
  border-bottom: 0px;
  border-left: 0.4em solid
  transparent;
  position: absolute;
  transform: rotate(270deg);
  color:
  #000;
  margin-left: 5px;
  margin-top: 10px;

}

.PlaceOrderCard {
  background-color: #f7f7f7;
  border:none;
}

.PlaceOrderCard .card-header{
  background-color: #39b54a;
  color: #fff;
  text-align: center;
  font-weight: bold;
  padding-top: 15px;
  padding-bottom: 15px;
}



.PlaceOrderCard .card-title {
  text-align: center;
  color: #013360;
  font-weight: bold;

}
.PlaceOrderCard .placebet{
  background-color: #39b54a;
  border-radius: 10px;
  padding: 10px 29px 10px;;

}


.PlaceOrderCard .custom-input-control button{

  background-color: #e7e7e7;
  border:none;
  padding: 0 15px 0px 15px;
  font-size: 25px;
font-weight: bold;
}

.PlaceOrderCard .custom-input-control input{
margin-left: 5px;
margin-right: 5px;

  background-color: #e7e7e7;
  font-weight: bold;
  text-align: center;
}


.text-blue{
  color: #013360;
}

.cardPopular{
  background-color: #f7f7f7;
  border:none;
}
.cardPopular .card-title{
  color: #39b54a;
  font-weight: bold;
}
.cardPopular button{
  background-color: #013360;
  color: #fff;
  border-radius: 10px;
  margin:.3rem;
}
.cardLive, .cardLive2{
  background-color: #f7f7f7;
  padding: 1em;
  border:none;
}

.cardLive2 .custom-switch .custom-control-label::after{
  left: calc(15.75rem + 2px);
}
.cardLive2 .custom-switch .custom-control-label::before{
  left: 15.75rem;
}



.cardLive .custom-switch .custom-control-label::after{
  left: calc(7.75rem + 2px);
}
.cardLive .custom-switch .custom-control-label::before{
  left: 7.75rem;
}
.custom-switch{
  padding-left: 1em;
}
.custom-switch  .custom-control-label{
   color: #39b54a;
   font-weight: bold;

}
.custom-switch .custom-control-input:checked ~ .custom-control-label::before{
background-color: #013360;
}
.cardMenu img{
  padding-top:0.4rem;
}
.cardMenu{
  background-color: #f7f7f7;
  padding: 1em 1em 0em 1em;
  border: none;

}
.cardMenu h4{
  color: #013360;
  padding-bottom: 0;

}
.cardMenu p{
  
  padding-bottom: 0;
  font-size: 14px;
}
.cardMenu.active {
  background-color: #39b54a;
  color: #fff;
}

.cardMenu.active h6{
  color: #fff;
}

.cardMenu h6{
  color: #013360;
  font-weight: bold;
 
}
.basecumHeading{
  border-bottom: 2px solid #dddddd;
}


.basecumHeading h6{
  white-space: break-spaces;
  font-weight: bold;
}
.basecumHeading .text-right p::after{
  display: inline-block;
  content: "";
  border-top: 0.4em solid;
  border-right: 0.4em solid
  transparent;
  border-bottom: 0px;
  border-left: 0.4em solid
  transparent;
  position: absolute;
  transform: rotate(0deg);
  color:
  #39b54a;
  margin-left: 0px;
  margin-top: 10px;


}
.headerAppPage{
  background-color: #fbfbfb;
}
.appPage .navbar-brand{
  padding-right: 3em;
}
.custom-search{
  width: 620px;
}

.custom-search button{
  background-color: #39b54a;
  border:none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

}
.custom-search input{
  border-radius: 8px;

}

#myModal .modal-header{
  background-color: #39b54a;
  color: #fff;
}

@media (min-width: 1200px){
 .appPage.container {
      max-width: 1410px;
  }
}

@media (min-width: 768.7px) and (max-width: 1023px) {
  .custom-search {
    width: 250px;
  }
 }

@media (max-width: 768px) { 

  .custom-search {
    width: 250px;
  }


.custom-card-home2 .arrow{
  display: none;
  }
  .headerHomePage{
    background: #fff;
  }
  h2.textBload{
    font-size: 24px;
  }

  .navbar{
    box-shadow: 5px 5px 5px #EAEAEA;
  }


  .btn-toolbar{
    width: 100%;
    margin-top: 25px;

  }
  .btn-toolbar .btn{
    width: 45%;

  }
  .homepageOklist li a{
    padding-left: 5px;
  }
  .centerContent{
    justify-content: center;
  }

h3.cuh3{
  font-size: 19px;
}
.footer{
  padding: 2em 5px;
}
.footer ul li{
  text-align: center;
}
.footer ul li::before{
  border: none;
}

.firstlink{
    padding-top: 3em;
  border-top: 2px solid
  #fff;
  margin-top: 3em;
  border-bottom: 2px solid
  #fff;
  margin-bottom: 3em;
  padding-bottom: 2em;

    
  }
  .subscribeus h4{
    text-align: center;
  }
  .subscribeus button{
    width: 100%;
  }
  .footer ul{
    padding-left: 0em;
  }
}
